import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Logo from "../components/logo"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(
          relativePath: { eq: "background-desktop-suppliers.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <Layout>
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
            <Navigation />
            <div className="main-body-content" style={{maxWidth: '500px'}}>
              <Logo />
              <h1 style={{marginTop: '40px'}}>Suppliers</h1>
              <p>
                There's nothing like stumbling across something that makes you
                say "oooohhh" and if that's something you can eat, all the
                better! We have a lovely range of yummy cakes, pastries and
                sambos available every day, but we're always looking for more
                delicious treats to stock. If you make something that makes
                people go "oooohhh" please get in touch with us.
              </p>
            </div>
          </BackgroundImage>
        </Layout>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
  height: 100vh;
  color: white;
`

export default StyledBackgroundSection
